<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button> </p>
                <div class="h-b"><el-button type="primary" size="small" @click="add" v-right-code="'Sysdictionary:Save'">新增</el-button> </div>
                <div class="h-b"><el-button type="text" size="small" @click="save" v-right-code="'Sysdictionary:Save'">保存</el-button> </div>
            </div>
        </div>

        <div class="form-list">
            <el-form ref="_sysDicInfoForm" :model="dataSource" :rules="sysDicCheckRule">
                <el-form-item>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span> 字典类型：</el-col>
                    <el-col :span="8">
                        <el-form-item>
                            <el-cascader
                                v-model="dataSource.DictionaryParentValues"
                                :options="dictionaryTreeList"
                                :props="{ checkStrictly: true,label:'DictionaryName',value:'DictionaryId',children:'Children' }"
                                clearable>
                            </el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span> 字典编码：</el-col>
                    <el-col :span="8">
                        <el-form-item prop="DictionaryCode">
                            <el-input v-model="dataSource.DictionaryCode" :maxlength="30" :minlength="4" :disabled="true" placeholder="字典编码" autofocus="autofocus"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>字典项名称：</el-col>
                    <el-col :span="8">
                        <el-form-item prop="DictionaryName">
                            <el-input v-model="dataSource.DictionaryName" :maxlength="30" :minlength="4" placeholder="字典项名称"></el-input>
                        </el-form-item>
                    </el-col>
                    
                   <el-col :span="3" class="form-title">字典项变量：</el-col>
                    <el-col :span="8">
                        <el-form-item prop="DictionaryVar">
                            <el-input v-model="dataSource.DictionaryVar" :maxlength="30" :minlength="4" placeholder="字典项变量"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">字典项序号：</el-col>
                    <el-col :span="8">
                        <el-form-item prop="Sort">
                            <el-input v-model="dataSource.Sort" :maxlength="30" :minlength="4" placeholder="字典项序号"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>字典项值：</el-col>
                     <el-col :span="19">
                        <el-form-item prop="DictionaryValue">
                            <el-input type="textarea" v-model="dataSource.DictionaryValue" :rows="10" :minlength="4" placeholder="字典项值"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">备注：</el-col>
                    <el-col :span="19">
                        <el-form-item prop="Remark">
                            <el-input type="textarea"
                            :rows="3"
                            placeholder="请输入内容"
                            v-model="dataSource.Remark">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <!-- <el-form-item>
                    <el-col :span="3" class="form-title">&nbsp;</el-col>
                    <el-col :span="5">
                        <el-form-item prop="IsDefaultCheck" v-if="dataSource.DictionaryParent!=701">
                            <el-switch v-model="dataSource.IsDefaultCheck" active-text="客户默认勾选"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-form-item> -->
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            var checkDictionaryName = function (rule, value, callback) {
                if (!value) return callback(new Error('字典名称不能为空'));
                callback();
            };
            return {
                multipleSelection: [],
                isDisabled: false,
                dictionaryTreeList: [],
                sysDicCheckRule: {
                    DictionaryName: [{ validator: checkDictionaryName, trigger: 'blur' }],
                    DictionaryValue:[{ required: true, message: '字典项值不能为空', trigger: 'blur' }],
                },
                dataSource: {},
                options: []
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                dataSourceKey:null
            }
        },
        mounted() {
            this.Event.$on("clearEditSysDicForm", () => this.resetForm);
            this.initialTreeList();
        },
        watch: {
            dataSource: {
                handler(curVal, oldVal) {
                    this.isDisabled = this.Utils.emptyGuid != this.dataSource.DictionaryID;
                },
                deep: true
            },
            'config.dataSourceKey':{
                handler(curVal, oldVal) {
                    if(curVal){
                        if(curVal!=oldVal) this.initial(curVal); //初始化
                    }else{
                        this.addInitial(); //新增初始化
                    }
                },
                deep: true
            }
        },
        methods: {
            initial(id){
                var _this = this;
                _this.$ajax.send("omsapi/sysdictionary/getbyid", "get", { id: id }, (data) => {
                    _this.Event.$emit("clearEditSysDicForm");
                    _this.dataSource=data.Result;
                });
            },
            add() {
                var _this = this;
                this.$ajax.send("omsapi/sysdictionary/init", "get", {}, (data) => {
                    _this.Event.$emit("clearEditSysDicForm");
                    _this.dataSource=data.Result;
                });
            },
            addInitial(){
                this.add();
            },
            resetForm() {
                !this.isPassValidate && this.$refs['_sysDicInfoForm'].resetFields();//清空表单
            },
            save() {
                var _this = this;
                _this.$refs["_sysDicInfoForm"].validate((valid) => {
                    _this.isPassValidate = valid;
                    if (valid) {
                        _this.dataSource.DictionaryType = 2;
                        _this.$ajax.send("omsapi/sysdictionary/save", "post", _this.dataSource, (data) => {
                            _this.Event.$emit("reloadSysDicPageList", data.Result);
                            _this.Utils.messageBox("保存成功.", "success");
                        });
                    } else {
                        return false;
                    }
                });
            },
            initialTreeList(){
                var _this = this;
                _this.$ajax.send("omsapi/sysdictionary/gettreelist", "get", {}, (data) => {
                    _this.dictionaryTreeList=data.Result;
                });
            }
        },
        components: {
        }
    }

</script>

<style>
</style>